<template>
  <div fluid class="error-page">
    <div class="wrapper">
      <div class="logo-wrapper">
        <v-img
          v-if="isLogoBase64 || !customLogo"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
          contain
          :src="customLogo ? customLogo : require('@/assets/logo.svg')"
          class="mb-10"
        ></v-img>
        <div
          v-else v-html="customLogo" class="mb-10 d-flex"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
        />
      </div>
      <div class="mt-auto">
        <div class="card">
            <span class="error-logo">
              <svg
                width="65"
                height="65"
                viewBox="0 0 65 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M49.5908 49.5301L49.5745 49.5465C48.2676 50.8582 48.2676 52.9847 49.5745 54.2964L58.1261 62.8789C59.433 64.1906 61.5519 64.1906 62.8588 62.8789L62.8752 62.8625C64.1821 61.5509 64.1821 59.4243 62.8752 58.1127L54.3236 49.5301C53.0167 48.2185 50.8977 48.2185 49.5908 49.5301Z"
                  fill="#EEEEF4"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M46.3978 46.3304L49.5828 49.527"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M28.4879 28.3558L34.9041 34.7952"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M34.9041 28.3558L28.4879 34.7952"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M31.696 52.793C43.3719 52.793 52.837 43.2936 52.837 31.5755C52.837 19.8574 43.3719 10.358 31.696 10.358C20.0201 10.358 10.555 19.8574 10.555 31.5755C10.555 43.2936 20.0201 52.793 31.696 52.793Z"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M4.55427 52.793L1.57699 49.8049"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M1.57699 52.793L4.55427 49.8049"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M52.6754 3.59428H48.8211"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M50.7367 5.63265V1.57909"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M59.0454 14.9211C60.1544 14.9211 61.0534 13.9774 61.0534 12.8133C61.0534 11.6491 60.1544 10.7054 59.0454 10.7054C57.9365 10.7054 57.0375 11.6491 57.0375 12.8133C57.0375 13.9774 57.9365 14.9211 59.0454 14.9211Z"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          <p class="error-text">{{ $t('heading.notFoundPage.title') }}</p>
          <p class="error-subtext">{{ $t('heading.notFoundPage.info') }}</p>
          <v-btn elevation="0" color="primary" :to="{ name: 'Home' }" :large="true">
              <span class="font-weight-600">
                {{ $t('button.home.back') }}
              </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
    isLogoBase64: function () {
      return this.customLogo && this.customLogo.trim().startsWith('data:image/');
    },
  },
};
</script>

<style scoped lang="scss">
.error-page {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 24px;

  .logo-wrapper {
    margin-top: 24px;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-grow: 0;

    .logo-title {
      font-size: $font-size-h2;
      font-weight: $font-weight-medium;
      color: white;
    }
  }

  .wrapper {
    max-height: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card {
    align-self: center;
    background-color: white;
    border-radius: 4px;
    max-width: 480px;
    width: 100%;
    padding: 16px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .error-logo {
      margin-bottom: 40px;
      width: 64px;
      height: 64px;
    }

    .error-text {
      color: var(--v-gray-darken2);
      text-align: center;
      font-size: $font-size-h1 !important;
      line-height: $line-height-h1;
      font-weight: $font-weight-bold;
    }

    .error-subtext {
      text-align: center;
      color: var(--v-gray-base);
      font-size: $font-size-base;
      line-height: $line-height-base;
      max-width: 278px;
      margin-bottom: 40px;
    }
  }
}
</style>
